import React from "react";
import { graphql } from "gatsby";

import EventDate from "../components/event-date/EventDate";
import Layout from "../components/layout/Layout";
import Posts from "../components/posts/Posts"
import Pagination from "../components/pagination/Pagination";

import styles from "./EventIndex.module.scss";

export const query = graphql
`query EVENTS (
    $skip: Int!,
    $limit: Int!
) {
    siteBuildMetadata {
        buildTime(formatString: "YYYY-MM-DD")
    }

    events: allMdx(
        sort: {
            fields: [frontmatter___startDate],
            order: [ASC]
        }
        filter: {
            fields: {
                due: {ne: "past"}
            }
            frontmatter: {
                published: {eq: true},
                type: {eq: "event"}
            }
        }
        limit: $limit
        skip: $skip
    ) {
        dates: group(field: frontmatter___startDate) {
            fieldValue
            nodes {
                slug
                fields {
                    due
                }
                frontmatter {
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                width: 254,
                                quality: 80,
                                formats: [WEBP, JPG],
                                layout: CONSTRAINED
                            )
                        }
                    }
                    title
                    date(formatString: "MMM D, YYYY")
                    dateSimple: date(formatString: "YYYY-MM-DD")
                    startDate(formatString: "MMM D, YYYY")
                    startDateSimple: startDate(formatString: "YYYY-MM-DD")
                    endDate(formatString: "MMM D, YYYY")
                    endDateSimple: endDate(formatString: "YYYY-MM-DD")
                    startTime
                    endTime
                    platform
                    productUrl
                    category
                    type
                }
            }
        }
    }
}
`

const EventIndex = ({ pageContext, data, location }) => {

    const { currentPage, numPages, pageHeading, count } = pageContext;

    const lastBuild = data.siteBuildMetadata.buildTime;

    return (
        <Layout
            location={location}
            className={styles.wrapper}
        >
            <div className={styles.posts}>
                <h2 className={styles.title}>
                    {pageHeading}
                    {currentPage !== 1 &&
                        <span>: page {currentPage}</span>
                    }
                </h2>

                {count === 0 &&
                    <p>There are currently no upcoming events to show.</p>
                }

                {data.events.dates.map(date => (
                    <div className={styles.group}>
                        <EventDate
                            date={date.fieldValue}
                            className={styles.date}
                            isToday={Date.parse(lastBuild) === Date.parse(date.fieldValue)}
                        />

                        <div className={styles.events}>
                            <Posts
                                data={date}
                                hideDate={true}
                            />
                        </div>
                    </div>
                ))}
            </div>

            {numPages > 1 &&
                <Pagination
                    currentPage={currentPage}
                    numPages={numPages}
                />
            }
        </Layout>
    )
}
            
export default EventIndex;